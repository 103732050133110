<template>
  <div class="flex flex-col mt-5 text-xl">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>

<style scoped>
p {
  margin-top: 1em;
}
</style>