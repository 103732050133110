<template>
  <div>
    <div class="mt-5 w-screen left-0 h-fit py-10 px-20 bg-gray-100">
      <span class="font-bold block text-gray-500 text-2xl"
        >Identity and Asian Australian poetry</span
      >
      <span class="font-bold block text-gray-400 text-xl"
        >An EALD Multimodal Presentation Project.</span
      >
      <span class="font-bold block text-gray-400 text-xl mt-5"
        >This website is created and hosted by Lincoln. The content is contributed by both Lincoln and
        Elijah. All content of this website (except otherwise indicated) are
        original work from Lincoln and Elijah and are subject to suitable
        copyright laws.</span
      >
      <span class="font-bold block text-gray-400 text-xl mt-5"
        >©️Yijun "Lincoln" Yan 2021, Shihao "Elijah" Liu 2021. All rights
        reserved.</span
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>