<template>
  <div>
    <nam-page>
      <paragraph-content>
        <div class="flex flex-col">
          <span class="text-5xl font-bold">
            What's our<dynamic-highlight> motivations?</dynamic-highlight>
          </span>
          <span class="text-3xl mt-2 border-b pb-5"
            >Insight: Why, and how does EliCon confront discriminations</span
          >
        </div>

        <subtitle>“The space, the silences between?”</subtitle>
        <pcontent>
          <p>
            Under the boosting globalisation,
            <dynamic-highlight>
              diasporic people seem to often get plagued by prejudice, biases or
              even discriminations </dynamic-highlight
            >. For the most common instance, asian students study math
            twenty-four-seven, and consequently, we are perceived as an
            undesired group from the outset. Black people are good at rapping
            and playing basketball.
          </p>
          <p>
            Those
            <dynamic-highlight>
              discriminations and prejudice are the consequences of
              generalisation of people in the society </dynamic-highlight
            >. The
            <dynamic-highlight>
              ambiguous inclarity between individualism and collectivism
            </dynamic-highlight>
            due to the<dynamic-highlight> unknown </dynamic-highlight> between
            races.
          </p>
          <p>
            Therefore, we conclude that
            <dynamic-highlight>
              One’s identity cannot be categorised based on race, birthplace or
              language.</dynamic-highlight
            >
          </p>
          <p>
            The adverse effects, such as prejudice and discriminations brought
            by the collective identity is also evident in Ee Tiang Chong’s poem,
            seen in “Not always negative, I’m more or less your images. The
            truth is always partly, a few hints here and there”. Ee challenges
            the “images” - which are the assumptions and prejudices brought by
            his collective identity through his use of low modality words, such
            as “Not always… More or less…” along with the uses of inclusive
            pronoun “your”, making a direct appeal to the audiences, prompting
            them to contemplate whether it is truly necessary to categorise and
            make assumptions on people purely based on their collective
            identity, such as their “race, language, religion, birthplace” and
            it does not truly describe a people. Advocating for the fact that
            <dynamic-highlight>
              people shouldn’t be categorised by collective
              identity.</dynamic-highlight
            >
          </p>
          <p>
            However, those chores are caused by the stereotypes and assumptions
            of a specific ethnical group. This is the area that
            <dynamic-highlight>
              EliCon is trying to challenge as a minor ethnicity who lives
              overseas.
            </dynamic-highlight>
            This is the part that we empathise with Ee, the rhetorical question
            in Some New Perspectives “what do they say of you and me,” forms a
            sibilance with “The space, the silences between?”, “space” and
            “silence” implies the barrier and disconnection the alliteration
            draws attention to the point of emphasis by
            <dynamic-highlight>
              instigating the readers to consider the discrepancy and nuances
              between individual and common collective identities </dynamic-highlight
            >, where each selfhood collectively belong to a culture, but it’s
            <dynamic-highlight>
              inadequate to represent one’s whole identity </dynamic-highlight
            >. Here we come to a conclusion, as we mentioned in the previous
            thesis, people's identities are ambiguous and fragmented. Therefore,
            we reckon
            <dynamic-highlight
              >people shouldn’t be restricted by the perception of a specific
              culture.</dynamic-highlight
            >
          </p>
          <p>
            In order to eradicate the discrimination as thorough as possible,
            the multiculturalism need to be promoted.
            <dynamic-highlight>
              EliCon focuses on introducing Asian culture into the local
              community of Australian in order to antagonise the prejudice and
              biases by eliminating unknowns.</dynamic-highlight
            >
          </p>
        </pcontent></paragraph-content
      ></nam-page
    >
  </div>
</template>

<script>
import DynamicHighlight from "../components/dynamic-highlight.vue";
import namPage from "../components/nam-page.vue";
import ParagraphContent from "../components/paragraph-content.vue";
import Pcontent from "../components/pcontent.vue";
import Subtitle from "../components/subtitle.vue";
export default {
  components: {
    namPage,
    ParagraphContent,
    DynamicHighlight,
    Subtitle,
    Pcontent,
  },
};
</script>

<style>
</style>